<template>
  <div class="chat-box">
    <div class="chat-box-content">
      <form class="chat-box-main main" id="chat-data" enctype="multipart/form-data" v-on:submit.prevent>
        <div class="chat-box-options">
          <UserAvatar :user="$user" size="30px" clean="true" />
          <label class="chat-box-button __files" v-if="!onbox">
            <span class="__inner"><iconic name="camera" /></span>
            <input
              type="file"
              accept="image/png, image/jpeg, video/mp4, video/mpg"
              multiple="multiple"
              @change="loadFiles"
              hidden
              :key="inputFileKey"
            />
          </label>
        </div>
        <div class="chat-box-text" @input="onResize">
          <TextareaRich
            name="message"
            placeholder="Escribir..."
            @input="inputMessage"
            @focus="onbox = true"
            @blur="onbox = false"
            @paste.prevent="onPaste"
          />
        </div>
        <div class="chat-box-send end">
          <a class="chat-box-button __send" v-if="loading"><iconic name="gspinner" /></a>
          <a class="chat-box-button __send" v-else @click="sendMessage"><iconic name="send" /></a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    TextareaRich: () => import("../widgets/TextareaRich.vue"),
  },
  props: ["user"],
  data: function() {
    return {
      loading: false,
      message: "",
      onbox: false,
      files: [],
      filesPreview: [],
      chatBoxHeight: 0,
      inputFileKey: 0,
    };
  },
  methods: {
    inputMessage: function({ target: { innerHTML } }) {
      this.message = innerHTML;
    },
    resetInputs: function() {
      this.loading = false;
      const messagebox = document.querySelector("#chat-data [name=message]");
      messagebox.innerHTML = "";
      this.message = "";
      this.files = [];
      this.filesPreview = [];
      this.emitFiles();
    },
    messageResponse: function(response) {
      if (!response.data) {
        return this.alert(Go.getErrorMessage(response));
      }

      const {
        data: { success, message, needSubscribe, creator },
      } = response;

      if (needSubscribe) {
        return this.BuySubscription(creator);
      }

      if (!success) {
        return this.alert({ type: "error", title: "Error", message });
      }

      this.resetInputs();
    },
    isReadyMessage: function() {
      this.message = this.message?.trim();

      if (!this.files.length && !this.message.length) {
        return {};
      }

      if (this.files.length && !this.message.length) {
        return { message: this.$locale["message_empty"] };
      }

      if (!this.message.length) {
        return { message: this.$locale["message_empty"] };
      }

      this.reqMaxChars = !this.$isCreator && !this.$isAdmin;

      if (this.reqMaxChars && this.message.length > this.MAX_MESSAGES_LENGTH) {
        return {
          icon: "error",
          title: "Error",
          message: `${this.$locale["message_length_limit_excceeded"]}, ${this.MAX_MESSAGES_LENGTH} ${this.$locale["characters_max"]}`,
        };
      }

      return { success: true };
    },
    sendMessage: async function() {
      this.message = this.message.trim();
      this.readySend = this.isReadyMessage();

      if (this.readySend.message) {
        return this.alert(this.readySend);
      }

      if (!this.readySend.success) {
        return;
      }

      try {
        this.loading = true;
        const formData = new FormData(document.querySelector("#chat-data"));
        formData.append("user", this.user);
        formData.append("message", encodeURIComponent(this.message));

        this.price = document.querySelector(".chatbox-files .FilesPrice [name=price]")?.value || 0;
        formData.append("price", this.price);

        if (this.files.length > 0) {
          this.files.forEach((file, index) => {
            formData.append(`file${index}`, file);
          });
        }

        this.$store.dispatch("sendMessage", { formData, callback: this.messageResponse, reset: this.resetInputs, filesPreview: this.filesPreview });
      } catch (error) {
        this.loading = false;
        this.alert({ type: "error", title: "Error", message: error.message });
      }
    },
    onResize: function({ target }) {
      const { height } = target.getBoundingClientRect();
      const list = document.querySelector(".view-chat-list");
      list.style.marginBottom = `${height}px`;
      this.chatBoxHeight = height;
      this.$emit("resize", { height });
    },
    emitFiles: function() {
      this.$emit("files", { files: this.files, preview: this.filesPreview, chatBoxHeight: this.chatBoxHeight });
    },
    filePreviewData: function(file) {
      return {
        name: file.name,
        size: file.size,
        type: file.type,
        src: URL.createObjectURL(file),
        id: this.$global.fastID(),
      };
    },
    loadFiles: function({ target: { files } }) {
      for (const file of files) {
        const valid = this.postIsValidFile(file);
        if (file.size && valid.success) {
          this.files.push(file);
          this.filesPreview.push(this.filePreviewData(file));
        } else {
          this.alert(valid);
        }
      }
      this.inputFileKey++;
      this.onResize({ target: this.el(".chat-box-content") });
      this.emitFiles();
    },
  },
};
</script>

<style lang="scss">
.chat-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: $dshadow;
  background-color: #fff;
  border-top: solid 1px $alto;
  &-content {
    padding: $mpadding/2 $mpadding;
  }
  &-main {
    @include Flex(inherit, center, center);
    gap: $mpadding;
  }
  &-text {
    flex: 1;
    overflow-y: auto;
    max-height: calc(var(--scrh) - var(--header-height));
    [name="message"] {
      padding: $mpadding/1.2 $mpadding;
      border-radius: $mpadding * 2;
      background-color: #f4f4f4;
      border: none;
      width: 100%;
      min-height: $mpadding * 2.5;
      margin: 0;
    }
  }

  &-options {
    @include Flex(inherit, center, center);
    margin: auto 0 0 0;
  }

  &-send {
    margin: auto 0 0 0;
  }

  &-button {
    display: flex;
    padding: $mpadding;
    margin: 0 $mpadding/2;
    user-select: none;
    .iconic {
      @include Flex(inherit, center, center);
    }
    &.__avatar {
      @include Flex(inherit, center, center);
      @include Avatar($mpadding * 2);
      color: #fff;
      font-weight: bold;
      font-size: 120%;
    }
    &.__files {
      color: $primary_color;
      padding: $mpadding/2 $mpadding;
      font-size: 150%;
    }
    &.__send {
      color: $primary_color;
    }
    &:not(.__avatar) {
      cursor: pointer;
      &:active {
        opacity: 0.5;
      }
    }
  }
}
</style>
